import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography, Box, CircularProgress } from "@mui/material";
import MKBox from "components/User/MKBox";
import MKTypography from "components/User/MKTypography";
import MKButton from "components/User/MKButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getArticles, getComments, addComment, getUsers } from "request/api";
import { useSnackbar } from "context/Snackbar";
import routes from "routes";
import footerRoutes from "footer.routes";
import DefaultNavbar from "components/User/Navbars/DefaultNavbar";
import DefaultFooter from "components/User/Footers/DefaultFooter";
import MKSocialButton from "components/User/MKSocialButton";
import { Helmet } from "react-helmet";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
const defaultImage = "https://via.placeholder.com/1200x800";

const extractImageUrl = (htmlContent) => {
  const imgTag = htmlContent.match(/<img [^>]*src="([^"]*)"[^>]*>/);
  return imgTag ? imgTag[1] : null;
};

const removeImagesFromHtml = (htmlContent) => {
  return htmlContent.replace(/<img[^>]*>/g, "");
};

function DetailledArticle() {
  const { slug } = useParams();
  const navigate = useNavigate(); // For redirecting
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isAddingComment, setIsAddingComment] = useState(false);
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchArticleAndCommentsAndUsers = async () => {
      try {
        const articles = await getArticles();

        // Filter articles to only include those where isVisible is true
        const visibleArticles = articles.filter((art) => art.isVisible);

        // Find the article that matches the slug
        const foundArticle = visibleArticles.find((art) => slugify(art.title) === slug);

        if (foundArticle) {
          setArticle(foundArticle);

          try {
            const allComments = await getComments(foundArticle._id);
            setComments(allComments || []);
          } catch (commentError) {
            console.error("Error fetching comments:", commentError);
            setComments([]);
          }

          try {
            const allUsers = await getUsers();
            setUsers(allUsers || []);
          } catch (userError) {
            console.error("Error fetching users:", userError);
            setUsers([]);
          }
        } else {
          setError(
            <Box
              sx={{
                minHeight: "100vh", // Ensure the page takes up the full viewport height
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between", // Keeps content spaced correctly
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center", // Center content vertically
                  textAlign: "center",
                  padding: 3,
                }}
              >
                <Typography variant="h2" component="div" gutterBottom>
                  Error 404
                </Typography>
                <Typography
                  variant="h5"
                  color="text.secondary"
                  sx={{ maxWidth: "600px", marginBottom: 4 }}
                >
                  This page is not available. The link might be incorrect. You can search for your
                  article or go back to the Articles page:
                </Typography>
                <Link to="/pages/landing-pages/articles" style={{ textDecoration: "none" }}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    sx={{
                      color: "text.primary",
                      "&:hover": {
                        color: "info.main",
                        "& .MuiSvgIcon-root": {
                          transform: "translateX(-5px)",
                        },
                      },
                      transition: "color 0.3s ease",
                    }}
                  >
                    <ArrowBackIcon
                      sx={{
                        fontSize: "3rem",
                        transition: "transform 0.3s ease",
                      }}
                    />
                    <MKTypography variant="button" fontWeight="medium" sx={{ ml: 1 }}>
                      Return to Articles Page
                    </MKTypography>
                  </MKBox>
                </Link>
              </Box>

              <MKBox sx={{ width: "100%", bgcolor: "background.paper", mt: "auto" }}>
                <DefaultFooter content={footerRoutes} />
              </MKBox>
            </Box>
          );
        }
      } catch (error) {
        setError(
          <Box
            sx={{
              minHeight: "100vh", // Ensure the page takes up the full viewport height
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", // Keeps content spaced correctly
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center", // Center content vertically
                textAlign: "center",
                padding: 3,
              }}
            >
              <Typography variant="h2" component="div" gutterBottom>
                Error 502
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                sx={{ maxWidth: "600px", marginBottom: 4 }}
              >
                There was an error with the fetch of articles, please verify your network connexion
                and try again
              </Typography>
              <Link to="/pages/landing-pages/articles" style={{ textDecoration: "none" }}>
                <MKBox
                  display="flex"
                  alignItems="center"
                  sx={{
                    color: "text.primary",
                    "&:hover": {
                      color: "info.main",
                      "& .MuiSvgIcon-root": {
                        transform: "translateX(-5px)",
                      },
                    },
                    transition: "color 0.3s ease",
                  }}
                >
                  <ArrowBackIcon
                    sx={{
                      fontSize: "3rem",
                      transition: "transform 0.3s ease",
                    }}
                  />
                  <MKTypography variant="button" fontWeight="medium" sx={{ ml: 1 }}>
                    Return to Articles Page
                  </MKTypography>
                </MKBox>
              </Link>
            </Box>

            <MKBox sx={{ width: "100%", bgcolor: "background.paper", mt: "auto" }}>
              <DefaultFooter content={footerRoutes} />
            </MKBox>
          </Box>
        );
        console.error("Error fetching article:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticleAndCommentsAndUsers();
  }, [slug]);

  const slugify = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  };

  // Function to check if the user is authenticated
  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;

    try {
      const decodedToken = jwtDecode(token);
      return !!decodedToken.id;
    } catch (error) {
      return false;
    }
  };

  const handleAddComment = async () => {
    if (!isAuthenticated()) {
      setSnackbar({
        open: true,
        message: "Please log in to add a comment.",
        color: "warning",
      });
      navigate("/pages/authentication/sign-in");
      return;
    }

    if (!newComment.trim()) {
      setSnackbar({
        open: true,
        message: "Please write something before adding a comment.",
        color: "error",
      });
      return;
    }

    setIsAddingComment(true);
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.id;

    try {
      const response = await addComment({
        content: newComment,
        user: userId,
        article: article._id,
      });
      setComments([...comments, response]);
      setNewComment("");
      setSnackbar({ open: true, message: "Comment added successfully!", color: "success" });
    } catch (error) {
      console.error("Error adding comment:", error);
      setSnackbar({ open: true, message: "Failed to add comment.", color: "warning" });
    } finally {
      setIsAddingComment(false);
    }
  };

  if (loading)
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
        }}
      >
        <CircularProgress color="success" />
      </Box>
    );
  if (error) return <p>{error}</p>;

  const imageUrl = extractImageUrl(article.content) || defaultImage;
  const contentWithoutImages = removeImagesFromHtml(article.content);

  const articleAuthor = users.find((user) => user._id === article.author.toString());

  // truncate Title
  const truncateTitle = (title, maxLength = 57) => {
    return title.length > maxLength ? title.slice(0, maxLength) + "..." : title;
  };
  // Truncate description
  // truncate Title
  const truncateDescription = (description, maxLength = 157) => {
    return description.length > maxLength ? description.slice(0, maxLength) + "..." : description;
  };

  return (
    <>
      {/* SEO */}
      <Helmet>
        <title>{truncateTitle(article.title)}</title>
        <meta name="description" content={truncateDescription(article.content)} />
        <meta
          name="keywords"
          content={`Association Elle & Lui, ${article.description},${articleAuthor.username}, ${articleAuthor.email}, ${new Date(article.createdAt).getFullYear()}, égalité, droits des femmes, genre`}
        />
        <meta property="og:title" content={`${article.title} - Elle & Lui`} />
        <meta
          property="og:description"
          content={`Read the article "${article.title}" published the ${new Date(article.createdAt).toLocaleDateString()} by ${articleAuthor.username}.`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:author" content={articleAuthor.username} />
        <meta property="og:email" content={articleAuthor.email} />
        <meta property="og:published_time" content={new Date(article.createdAt).toISOString()} />
        <meta
          property="og:image"
          content={imageUrl} // Replace with actual image URL if available
        />
        <meta name="author" content={articleAuthor.username} />
      </Helmet>

      <DefaultNavbar
        routes={routes}
        action1={{
          type: "internal",
          route: "/pages/landing-pages/donate",
          label: "Donate",
        }}
        sticky
      />

      <Container>
        <Card
          sx={{
            p: 1,
            mx: { xs: 0.1, lg: 3 },
            mt: 4,
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <MKBox mt={15}>
            <img
              src={imageUrl}
              alt={article.title}
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "1200px",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          </MKBox>
          <MKBox
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <MKTypography
              variant="h4"
              gutterBottom
              sx={{
                textAlign: "left", // Center the text for better visual appeal
                lineHeight: 1.5, // Balanced line height
                wordSpacing: "normal", // Ensures word spacing is normal
                letterSpacing: "normal", // Maintains natural letter spacing
              }}
            >
              {article.title}
            </MKTypography>

            <MKTypography variant="body1">
              <div dangerouslySetInnerHTML={{ __html: contentWithoutImages }} />
            </MKTypography>
            <MKBox
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                fontStyle: "italic",
                borderTop: "1px solid #ddd",
                pt: 2,
              }}
            >
              <MKTypography variant="body2" color="textSecondary">
                <strong>Creation Date:</strong>{" "}
                {isNaN(new Date(article.createdAt).getTime())
                  ? "Invalid Date"
                  : new Date(article.createdAt).toLocaleDateString()}
              </MKTypography>
              <MKTypography variant="body2" color="textSecondary" textAlign="center">
                <strong>Share this article on: </strong>
              </MKTypography>
            </MKBox>
          </MKBox>
          <MKBox component="section" py={2}>
            <Container>
              <MKBox display="flex" flexWrap="wrap" justifyContent="center" gap={1}>
                <MKSocialButton
                  sx={{ backgroundColor: "#25D366", "&:hover": { backgroundColor: "#1DA851" } }}
                  iconOnly
                  href={`https://api.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppIcon />
                </MKSocialButton>

                <MKSocialButton
                  color="facebook"
                  iconOnly
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </MKSocialButton>

                <MKSocialButton
                  color="twitter"
                  iconOnly
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon />
                </MKSocialButton>

                <MKSocialButton
                  color="linkedin"
                  iconOnly
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon />
                </MKSocialButton>
                {/* Instagram (Pas de lien de partage, juste l'icône) */}
                <MKSocialButton color="instagram" iconOnly>
                  <InstagramIcon />
                </MKSocialButton>
              </MKBox>
            </Container>
          </MKBox>
        </Card>
        <MKBox sx={{ mt: 4 }}>
          <MKTypography variant="h4" gutterBottom>
            Comments
          </MKTypography>
          <Card
            sx={{
              p: 2,
              boxShadow: ({ boxShadows: { md } }) => md,
              borderRadius: "8px",
            }}
          >
            {comments.length > 0 ? (
              comments.map((comment) => {
                const user = users.find((user) => user._id === comment.user.toString());
                return (
                  <MKBox
                    key={comment._id}
                    sx={{
                      mb: 2,
                      p: 2,
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      position: "relative",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <MKTypography
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: comment.content }}
                    />
                    <MKTypography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                      <strong>Author:</strong>{" "}
                      {user ? `${user.username} (${user.email})` : "Unknown"}
                    </MKTypography>
                    <MKTypography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mt: 1, fontStyle: "italic" }}
                    >
                      {new Date(comment.createdAt).toLocaleString()}
                    </MKTypography>
                  </MKBox>
                );
              })
            ) : (
              <MKTypography>No comments yet.</MKTypography>
            )}
          </Card>
          <MKBox sx={{ mt: 4 }}>
            <ReactQuill
              value={newComment}
              onChange={setNewComment}
              placeholder="Add a comment..."
            />
            <MKButton
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              sx={{ mt: 2 }}
              disabled={isAddingComment}
            >
              {isAddingComment ? "Adding Comment..." : "Add Comment"}
            </MKButton>
          </MKBox>
        </MKBox>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Container>
    </>
  );
}

export default DetailledArticle;
